<template>
  <b-card
    class="purchaseinboundinvoice-edit-wrapper"
  >
    <!-- form -->
    <b-form id="purchaseinboundinvoiceForm" class="edit-form mt-2">
      <b-row>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="Util.setno('JXFP')"
            label-for="in_no"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="in_no"
              size="sm"
              v-model="purchaseinboundinvoice.in_no"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="采购订单Id purchase_order.order_id"
            label-for="order_id"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="order_id"
              size="sm"
              v-model="purchaseinboundinvoice.order_id"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="采购订单号 purchase_order.order_no"
            label-for="order_no"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="order_no"
              size="sm"
              v-model="purchaseinboundinvoice.order_no"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="采购订单明细 purchase_order_item.item_id"
            label-for="orderitem_id"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="orderitem_id"
              size="sm"
              v-model="purchaseinboundinvoice.orderitem_id"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="入库单Id purchase_inbound.id"
            label-for="inbound_id"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="inbound_id"
              size="sm"
              v-model="purchaseinboundinvoice.inbound_id"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="入库单明细 purchase_inbound_item.inbounditem_id"
            label-for="inbounditem_id"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="inbounditem_id"
              size="sm"
              v-model="purchaseinboundinvoice.inbounditem_id"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="入库单编号 purchase_inbound.inbound_no"
            label-for="inbound_no"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="inbound_no"
              size="sm"
              v-model="purchaseinboundinvoice.inbound_no"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="采购日期 purchase_order.create_time"
            label-for="purchase_time"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="purchase_time"
              size="sm"
              v-model="purchaseinboundinvoice.purchase_time"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="供应商ID purchase_order.supplier_id"
            label-for="supplier_id"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="supplier_id"
              size="sm"
              v-model="purchaseinboundinvoice.supplier_id"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="供应商名称 purchase_order.supplier_name"
            label-for="supplier_name"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="supplier_name"
              size="sm"
              v-model="purchaseinboundinvoice.supplier_name"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="签约主体ID purchase_order.company_id"
            label-for="company_id"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="company_id"
              size="sm"
              v-model="purchaseinboundinvoice.company_id"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="签约主体名称 purchase_order.company_name"
            label-for="company_name"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="company_name"
              size="sm"
              v-model="purchaseinboundinvoice.company_name"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="入库商品ID purchase_inbound_item.product_id"
            label-for="product_id"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="product_id"
              size="sm"
              v-model="purchaseinboundinvoice.product_id"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="入库商品名称 purchase_inbound_item.name"
            label-for="name"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="name"
              size="sm"
              v-model="purchaseinboundinvoice.name"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="实际入库数量 同一采购单，同一商品Id，同一价格会累加"
            label-for="trueqty"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="trueqty"
              size="sm"
              v-model="purchaseinboundinvoice.trueqty"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="含税单价 purchase_order_item.product_cost_tax"
            label-for="product_cost_tax"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="product_cost_tax"
              size="sm"
              v-model="purchaseinboundinvoice.product_cost_tax"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="税率 purchase_order_item.tax_rate"
            label-for="tax_rate"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="tax_rate"
              size="sm"
              v-model="purchaseinboundinvoice.tax_rate"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="入库金额 （入库数量-退货数量）*含税单价"
            label-for="delivery_amount"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="delivery_amount"
              size="sm"
              v-model="purchaseinboundinvoice.delivery_amount"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="开票状态 invoice_status"
            label-for="invoicing_status"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="invoicing_status"
              size="sm"
              v-model="purchaseinboundinvoice.invoicing_status"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="状态"
            label-for="state"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="state"
              size="sm"
              v-model="purchaseinboundinvoice.state"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="添加时间"
            label-for="create_time"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="create_time"
              size="sm"
              v-model="purchaseinboundinvoice.create_time"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="添加人ID"
            label-for="creator"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="creator"
              size="sm"
              v-model="purchaseinboundinvoice.creator"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="更新时间"
            label-for="update_time"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="update_time"
              size="sm"
              v-model="purchaseinboundinvoice.update_time"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="更新人ID"
            label-for="updator"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="updator"
              size="sm"
              v-model="purchaseinboundinvoice.updator"
            />
          </b-form-group>
        </b-col>
                <b-col
          cols="12"
          class="mt-50"
        >
          <b-button
            variant="primary"
            class="mr-1"
            @click="save"
          >
            保存
          </b-button>
          <b-button
            variant="outline-secondary"
            @click="cancel"
          >
            取消
          </b-button>
        </b-col>
      </b-row>
    </b-form>
    <!--/ form -->
  </b-card>
</template>

<script>
import {
  BCard, BMedia, BAvatar, BCardText, BForm, BRow, BCol, BFormGroup, BFormInput, BImg, BFormFile, BLink, BButton,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import {onUnmounted, ref} from '@vue/composition-api'
import router from "@/router";
import store from "@/store";
import purchaseinboundinvoiceStore from './purchaseinboundinvoiceStore'
// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { toTime, toDate, getCodeOptions, getCode, getCodeLabel, getCodeColor } from '@core/utils/filter'

export default {
  components: {
    BCard,
    BMedia,
    BAvatar,
    BCardText,
    BForm,
    BLink,
    BImg,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BFormFile,
    vSelect,
    useToast,
  },
  data() {
    return {
      id: ref(0),
      purchaseinboundinvoice: ref({}),
    }
  },
  setup() {
    const toast = useToast()

    // Register module
    if (!store.hasModule('purchaseinboundinvoice')) store.registerModule('purchaseinboundinvoice', purchaseinboundinvoiceStore)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule('purchaseinboundinvoice')) store.unregisterModule('purchaseinboundinvoice')
    })

    const edit = function() {
      store.dispatch('purchaseinboundinvoice/edit', {id: this.id}).then(res => {
        this.purchaseinboundinvoice = res.data.data
      })
    }

    const view = function() {
      store.dispatch('purchaseinboundinvoice/view', {id: this.id}).then(res => {
        this.purchaseinboundinvoice = res.data.data
      })
    }

    const cancel = function() {
      this.$router.go(-1)
    }

    const save = function() {
      store.dispatch('purchaseinboundinvoice/save', this.purchaseinboundinvoice).then(res => {
        toast.success('数据已保存!')
        this.$router.push({ name: 'apps-${classVar}-list'});
      })
    }

    return {
      edit,
      view,
      cancel,
      save,

      getCodeOptions,
      getCode,
      getCodeLabel,
      getCodeColor,
    }
  },
  created() {
    this.id = this.$route.query.id || 0;
    this.edit()
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
