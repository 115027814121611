import axios from '@axios'
import qs from 'qs'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    search(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/purchaseinboundinvoice/search', {params: params})
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    view(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/purchaseinboundinvoice/view', {params: params})
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    edit(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
            .get('/api/purchaseinboundinvoice/edit', {params: params})
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },
    save(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/purchaseinboundinvoice/save', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    state(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
            .post('/api/purchaseinboundinvoice/state', params)
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },
    createPurchaseInvoice(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
            .post('/api/purchaseinboundinvoice/createPurchaseInvoice', params)
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },
    searchPurchaseInvoice(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
            .post('/api/purchaseinboundinvoice/searchPurchaseInvoice', params)
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },
    searchPurchaseInboundInvoiceByInvoiceId(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
            .post('/api/purchaseinboundinvoice/searchPurchaseInboundInvoiceByInvoiceId', params)
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },
    editPurchaseInvoice(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
            .post('/api/purchaseinboundinvoice/editPurchaseInvoice', params)
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },
    delPII(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
            .post('/api/purchaseinboundinvoice/delPII', params)
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },
    delPI(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
            .post('/api/purchaseinboundinvoice/delPI', params)
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },
    importExcel(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
            .post('/api/purchaseinboundinvoice/importExcel', params)
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },
  },
}
